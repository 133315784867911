import './App.css';

import * as React from 'react';

import { useCallback, useEffect, useRef, useState } from 'react';

type CalculationResult = {
  average: number,
  min: number,
  max: number,
  reps: number,
  weight: number,
  units: 'lb' | 'kg',
};

function App() {
  const weightLiftedRef = useRef<HTMLInputElement | null>(null);
  const weightUnitsRef = useRef<HTMLSelectElement | null>(null);
  const numRepsRef = useRef<HTMLInputElement | null>(null);
  const [result, setResult] = useState<CalculationResult | null>(null);

  const handleClick = useCallback(() => {
    const weightLifted = weightLiftedRef.current?.value;
    const numReps = numRepsRef.current?.value;
    const weightUnits = weightUnitsRef.current?.value || 'lb';
    if (weightLifted != null && numReps != null) {
      fetch('api/index.php?' + new URLSearchParams({
        weightLifted, numReps, weightUnits
      }))
      .then(response => response.json())
      .then(data => setResult(data));
    }
  }, []);

  return (
    <div>
      <header>
        <h1>reps to max</h1>
      </header>
      <section>
        <p>Calculate your max lift based on how many repetitions you can lift a sub-maximal load</p>
      </section>
      <section className="center">
        <h2>Calculator</h2>
        <div className="m-20">
          <input className="input weight-lifted-input" type="number" name="weight_lifted" min="1" max="4000" placeholder="weight" ref={weightLiftedRef} />
          <select className="select" name="weight_units" ref={weightUnitsRef}>
            <option value="lb">lb</option>
            <option value="kg">kg</option>
          </select>
        </div>
        <div className="m-20">
          <input className="input num-reps-input" type="number" name="num_reps" min="1" max="40" ref={numRepsRef} maxLength={2} placeholder="# reps" />
        </div>
        <div className="m-20">
          <button className="button" onClick={handleClick}>calculate</button>
        </div>
        {result != null ?
          (
            <div className="result-container">
              <div className="result-title">
                <h2>Result</h2>
              </div>
              <div className="result-block">
                <div>{result.reps} reps @ {result.weight} {result.units} ≈ {result.average} {result.units} 1RM</div>
              </div>
            </div>
          ) : null
        }
      </section>
    </div>
  );
}

export default App;
